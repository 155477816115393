import CryptoJS from "crypto-js";
import { API_BASE_URL, API_ID, LOGIN_KEY } from './config';
import axios from "axios";
import { toNumber } from "lodash";
import AsyncStorage from "../storage-helper/AsyncStorage";

export async function authenticate(): Promise<void> {

    const AccessTokenExpiresIn = await AsyncStorage.getItem("AccessTokenExpiresIn");
    const expiresIn = toNumber(AccessTokenExpiresIn);

    if (!isNaN(expiresIn) && expiresIn > Date.now()) {
        console.log('Access token is still valid.');
        return;
    }

    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://spyduck-8887f.ew.r.appspot.com/auth',
            headers: {
                'Content-Type': 'application/json'
            }
        } as any;

        const response = await axios.request(config) as any

        await AsyncStorage.setItem("AccessToken", response.data.accessToken);
        const expiresIn = Date.now() + (response.data.expiresIn * 1000);

        await AsyncStorage.setItem("AccessTokenExpiresIn", expiresIn.toString());
        console.log('Authentication Successful');
    } catch (error: any) {
        console.error('Authentication Failed:', error.message || error);
    }
}

export { API_BASE_URL };
