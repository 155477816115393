import React, { useRef, useState, useEffect } from "react"
import "./FHMap.css";
import * as ol from "ol";
import FHMapContext from "./FHMapContext";
import { DeviceHistoryTrack } from "../../model/DeviceHistoryTrack";
import TileLayer from "ol/layer/Tile";
import { ImageTile } from "ol";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { getVectorContext } from "ol/render";

const FHMap = ({ children, zoom, center, deviceHistory }: any) => {
    const mapRef = useRef();
    const [map, setMap] = useState(null as any);
    const [animating, setAnimating] = useState(false);
    const [enableReCenter, setEnableReCenter] = useState(true);

    useEffect(() => {
        let options = {
            view: new ol.View({ zoom, center }),
            layers: [],
            controls: [],
            overlays: []
        };
        let mapObject = new ol.Map(options);
        mapObject.setTarget(mapRef.current);
        setMap(mapObject);

        return () => mapObject.setTarget(undefined);
    }, []);

    useEffect(() => {
        if (!map) return;
        map.getView().setZoom(zoom);
    }, [zoom]);

    useEffect(() => {
        if (!map) return;
        map.getView().setCenter(center)
    }, [center])

    map && map.on("moveend", () => {
        if (enableReCenter) {
            setEnableReCenter(false);
        }
    });

    const removeLayerById = (map: any, layerId: any) => {
        const layers = map.getLayers().getArray();
        for (let i = 0; i < layers.length; i++) {
            const layer = layers[i];
            if (layer.get('id') === layerId) {
                map.removeLayer(layer);
                console.log(`Layer ${layerId} removed`);
                break;
            }
        }
    }

    useEffect(() => {
        if (!map) return;

        if (deviceHistory.length == 0) {
            removeLayerById(map, 'routeLayer');
            return;
        }

        const coordinates = deviceHistory.map((track: DeviceHistoryTrack) => [
            parseFloat(track.lng),
            parseFloat(track.lat),
        ]);

        const route = new LineString(coordinates).transform("EPSG:4326", "EPSG:3857");

        const routeFeature = new Feature({
            type: "route",
            geometry: route,
        });

        const startMarker = new Feature({
            type: "icon",
            geometry: new Point((route as LineString).getFirstCoordinate()),
        });

        const endMarker = new Feature({
            type: "icon",
            geometry: new Point((route as LineString).getLastCoordinate()),
        });

        const position = startMarker.getGeometry()?.clone() as Point;
        const geoMarker = new Feature({
            type: "geoMarker",
            geometry: position,
        });

        const styles: Record<string, Style> = {
            route: new Style({
                stroke: new Stroke({
                    width: 6,
                    color: [237, 212, 0, 0.8],
                }),
            }),
            icon: new Style({
                image: new Icon({
                    anchor: [0.5, 1],
                    src: "data/icon.png",
                }),
            }),
            geoMarker: new Style({
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({ color: "black" }),
                    stroke: new Stroke({
                        color: "white",
                        width: 2,
                    }),
                }),
                zIndex: 1000,
            }),
        };

        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [startMarker, endMarker, geoMarker, routeFeature],
            }),
            style: (feature) => styles[feature.get("type")],
        });

        vectorLayer.set('id', 'routeLayer');

        map.addLayer(vectorLayer);

        console.log("layer added")

        let distance = 0;
        let lastTime: number;

        const moveFeature = (event: any) => {
            const speed = 1;
            const time = event.frameState.time;
            const elapsedTime = time - lastTime;
            distance = (distance + (speed * elapsedTime) / 1e6) % 1;
            lastTime = time;

            const currentCoordinate = (route as LineString).getCoordinateAt(distance);
            position.setCoordinates(currentCoordinate!);
            const vectorContext = getVectorContext(event);
            vectorContext.setStyle(styles.geoMarker);
            vectorContext.drawGeometry(position);
            map.render();
        };

        const startAnimation = () => {
            setAnimating(true);
            lastTime = Date.now();
            vectorLayer.on("postrender", moveFeature);
            geoMarker.setGeometry(null as any);
        };

        const stopAnimation = () => {
            setAnimating(false);
            geoMarker.setGeometry(position);
            vectorLayer.un("postrender", moveFeature);
        };

        document.getElementById('start-animation')?.addEventListener('click', function () {
            if (animating) {
                console.log("stop animating")
                stopAnimation();
            } else {
                console.log("start animating")
                startAnimation();
            }
        });

    }, [map, animating, deviceHistory]);

    return (
        <FHMapContext.Provider value={{ map } as any}>
            <div ref={mapRef as any} style={{ width: "100%", height: "100%" }}>
                {children}
            </div>
            <div id="popup" style={{ backgroundColor: "white" }}>
                <a href="#" id="popup-closer"></a>
                <div id="popup-content"></div>
            </div>

        </FHMapContext.Provider>
    )
}
export default FHMap;