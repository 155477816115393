import React, { useContext, useEffect, useState } from "react";
import FHMap from "./FHMap";
import { Style, Icon, Stroke, Text, Fill } from "ol/style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { osm, vector } from "./Source";
import { fromLonLat, get } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import WKT from "ol/format/WKT";
import mapConfig from "./config.json";
import Controls from "./Controls/Controls";
import FullScreenControl from "./Controls/FullScreenControl";
import Layers from "./Layers/Layers";
import TileLayer from "./Layers/TileLayer";
import VectorLayer from "./Layers/VectorLayer";
import FeatureStyles from "./Features/Styles";
import FHGeoLocation from "./FHGeoLocation";
import { MarkerCoordinate } from "../../model/MarkerCoordinate";
import FHMapInteractions from "./Controls/FHMapInteractions";
import utils from "./Utils/utils";
import Polyline from 'ol/format/Polyline';
import { Button, message } from "antd";
import FHOverlays from "./Controls/FHOverlays";
import Geometry from "ol/geom/Geometry";
import moment from "moment";
import { ShuttleBaseTrackRoute } from "../../model/ShuttleBaseTrackRoute";
import { Shuttle } from "../../model/Shuttle";
import { getTableItems, getTableItemsWithPath, getTableItemWithId } from "../../dto/ServerHelper";
import { XYZ } from "ol/source";
import { Passenger } from "../../model/Passenger";
import RouteDetermination from "../Shuttle/RouteDetermination";
import UserContext from '../../Context/User';
import { User } from "../../model/User";
import { Car } from "../../model/Car";
import { toNumber } from "lodash";
import { LocationData } from "../../model/LocationData";
import { database } from "../../dto/FireBaseDB";
import { onChildChanged, query, ref } from "firebase/database";
import redbusmarker from "../../images/redBus.png";
import yellowbusmarker from "../../images/yellowBus.png";
import CarContext from "../../Context/CarContext";
import { DeviceHistoryTrack } from "../../model/DeviceHistoryTrack";

interface TrackingMapAppProps {
    isGeolocationActive?: boolean;
    isInteractionsOpen?: boolean;
    deviceHistoryRouteCoordinates?: DeviceHistoryTrack[];
    passengerCoordinates?: MarkerCoordinate[];
    isCalculateRoute?: boolean;
    saveShuttleBaseRoute?: any;
    baseTrackRoute?: ShuttleBaseTrackRoute;
    shuttle?: Shuttle;
    mapCenter?: number[];
    watchShuttleBus?: boolean;
    setActiveCarItems: any;
    followSelectedCar: boolean;
    selectedCar: Car;
}

const TrackingMapApp = (props: TrackingMapAppProps) => {
    const [center, setCenter] = useState(mapConfig.center);
    const [zoom, setZoom] = useState(14);
    const [features, setFeatures] = useState([] as unknown as Feature<Geometry>[]);
    const [routeFeatures, setRouteFeatures] = useState([] as unknown as Feature<Geometry>[]);
    const [allShuttlesPolygonFeature, setAllShuttlesPolygonFeature] = useState(undefined as any);
    const [schoolFeature, setSchoolFeature] = useState(undefined as unknown as Feature<Geometry>);
    const [busFeature, setBusFeature] = useState(undefined as unknown as Feature<Geometry>);
    const [geojsonObject, setGeojsonObject] = useState(undefined as any);
    const [calculatedRoute, setCalculatedRoute] = useState(undefined as unknown as ShuttleBaseTrackRoute);
    const [points, setPoints] = useState([] as number[]);
    const [shuttlRouteByDate, setShuttlRouteByDate] = useState([] as number[]);
    const [shuttlRouteDate, setShuttlRouteDate] = useState(undefined);
    const [deviceCoordinates, setDeviceCoordinates] = useState([] as MarkerCoordinate[]);

    const [user, setUser] = useContext(UserContext);
    const [carItems, setCarItems] = useContext(CarContext);

    useEffect(() => {
        setCarInformation(user as User);
    }, [])

    useEffect(() => {
        if (props.baseTrackRoute) {
            const feature = ConvertRouteGeometryToFeature(props.baseTrackRoute.Geometry);
            setRouteFeatures(oldArray => [feature, ...oldArray]);
        }
    }, [props.baseTrackRoute])

    useEffect(() => {
        if (props.mapCenter)
            setCenter(props.mapCenter);
    }, [props.mapCenter])

    useEffect(() => {
        if (!carItems || !Array.isArray(carItems)) {
            message.success({
                content: 'Araçlar verisi bulunamadığı için cihazlar sorgulanamadı!',
                style: {
                    zIndex: "999999999999999",
                    marginTop: '200vh',
                },
            });
            return;
        }

        getTableItemsWithPath(`/WanWayDB/gps`).then((data) => {
            const itemsArray: MarkerCoordinate[] = [];

            Object.keys(data).map(imei => {
                const deviceData = data[imei] as LocationData;
                var time = moment(deviceData.time * 1000).format("DD-MM-YYYY H:mm:ss");

                const carInfo = carItems.find((carItem: Car) => carItem.IMEI?.toString() === deviceData.imei) as Car;
                const speed = deviceData.speed == undefined ? "0" : deviceData.speed.toString();
                const item = {
                    Id: deviceData.imei,
                    Info: carInfo?.CarNumberPlate,
                    Description: 'Sinyal: ' + time + ' Hiz: ' + speed,
                    SignalTime: time,
                    Speed: speed,
                    Latitude: tryParseFloat(deviceData.lat),
                    Longitude: tryParseFloat(deviceData.lng),
                    Course: deviceData.course == undefined ? 0 : deviceData.course,
                    Status: ""
                } as MarkerCoordinate;

                itemsArray.push(item);
            });

            setDeviceCoordinates(itemsArray);
        });

        onChildChanged(query(ref(database, `/WanWayDB/gps`)), (snapshot: { val: () => LocationData; }) => {

            const data = snapshot.val() as LocationData;

            var time = moment(data.time * 1000).format("DD-MM-YYYY H:mm:ss");

            const carInfo = carItems.find((carItem: Car) => carItem.IMEI?.toString() === data.imei) as Car;

            const newItem = {
                Id: data.imei,
                Info: carInfo?.CarNumberPlate,
                Description: 'Sinyal:' + time + ' Hiz: ' + data.speed,
                SignalTime: time,
                Speed: data.speed.toString(),
                Latitude: tryParseFloat(data.lat),
                Longitude: tryParseFloat(data.lng),
                Course: data.course,
                Status: ""
            } as MarkerCoordinate;

            setDeviceCoordinates((oldArray: any) => {
                const index = oldArray.findIndex((item: any) => item.Id === newItem.Id);

                if (index !== -1) {
                    const updatedArray = [...oldArray];
                    updatedArray[index] = newItem;
                    return updatedArray;
                } else {
                    return [...oldArray, newItem];
                }
            });
        });

    }, [carItems]);

    useEffect(() => {
        if (deviceCoordinates && deviceCoordinates.length > 0) {

            if (props.followSelectedCar) {
                handleSelectedCarCoordinateChanges(deviceCoordinates);
                return;
            }

            const activeCarsWithSpeed = deviceCoordinates
                .filter(marker => {
                    const car = carItems.find((car: Car) => car.CarNumberPlate === marker.Info);
                    return car && parseFloat(marker.Speed || "0") > 0;
                })
                .map(marker => {
                    const car = carItems.find((car: Car) => car.CarNumberPlate === marker.Info);
                    return car;
                });

            props.setActiveCarItems(activeCarsWithSpeed);

            // eslint-disable-next-line prefer-const
            let featuresPassenger: any = addMarkers(deviceCoordinates);

            setFeatures(oldArray => [...featuresPassenger, ...oldArray]);

        }
    }, [deviceCoordinates])

    useEffect(() => {
        if (props.followSelectedCar) {
            handleSelectedCarCoordinateChanges(deviceCoordinates);
            setZoom(18)
        }

    }, [props.followSelectedCar, props.selectedCar])

    const handleSelectedCarCoordinateChanges = (deviceCoordinatesResult: any[]) => {

        console.log("props.selectedCar")

        const info: MarkerCoordinate | undefined = deviceCoordinatesResult.find((item: MarkerCoordinate) =>
            item !== undefined && item.Id.toString() === props.selectedCar?.IMEI?.toString());
        console.log(info)
        if (info?.Latitude) {
            setCenter([info.Longitude, info.Latitude]);
        }
    }

    const tryParseFloat = (val: string) => {
        try {
            return parseFloat(val);
        } catch (error) {
            return 0;
        }
    }

    const setCarInformation = async (user: User) => {

        await getTableItems("Car").then((result: Car[]) => {
            const items = result.filter((carItem: Car) => {
                return user.Organization === toNumber(carItem.Organisation);
            });

            if (!(items && items.length > 0)) {
                return;
            }
            console.log(items)

            setCarItems(items);

        }).catch((error) => {
            console.log(error)
            message.success({
                content: 'Arac bilgileri alınırken hata oluştu tekrar deneyiniz!',
                style: {
                    zIndex: "999999999999999",
                    marginTop: '200vh',
                },
            });
            return false;
        });
    }

    // eslint-disable-next-line prefer-const
    let styleFunction = (feature: any, markerImage: any, course: number) => {
        return [
            new Style({
                image: new Icon({
                    anchorXUnits: "fraction",
                    anchorYUnits: "pixels",
                    src: markerImage,
                    rotation: course * (Math.PI / 180),
                    scale: 0.025
                }),
                stroke: new Stroke({
                    color: [0, 0, 0, 1.0],
                    width: 1,
                    lineDash: [1, 5, 3, 5]
                }),
                text: new Text({
                    font: '12px Calibri',
                    text: feature.get('Info') as any,
                    placement: 'line',
                    fill: new Fill({
                        color: '#fff'
                    }),
                    stroke: new Stroke({
                        color: '#000',
                        width: 4
                    })
                }),
            })
        ];
    }

    const addMarkers = (markerCoordinate: MarkerCoordinate[]) => {

        // eslint-disable-next-line prefer-const
        let features = markerCoordinate.map((item: MarkerCoordinate) => {

            const speed = item.Speed ? tryParseFloat(item.Speed) : undefined;
            const isActive = speed !== undefined && !isNaN(speed) && speed > 0;

            var markerImage = isActive ? yellowbusmarker : redbusmarker;

            // eslint-disable-next-line prefer-const
            let feature = new Feature({
                geometry: new Point(fromLonLat([item.Longitude, item.Latitude])),
            });
            feature.setId(item.Id)
            feature.set('Info', item.Info)
            feature.setStyle(styleFunction(feature, markerImage, item.Course));
            feature.set("type", 'place');

            return feature;
        });
        return features;
    }

    const ConvertRouteGeometryToFeature = (polyline: any) => {

        // eslint-disable-next-line prefer-const
        let route = new Polyline({
            factor: 1e5
        }).readGeometry(polyline, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });

        // eslint-disable-next-line prefer-const
        let feature = new Feature({
            type: 'route',
            geometry: route
        });

        const styles = [
            // linestring
            new Style({
                stroke: new Stroke({
                    color: 'green',
                    lineDash: [4],
                    width: 4
                }),
            }),
        ];

        feature.setStyle(styles);

        return feature;
    }

    const getNearestNext = (coordinate: number[], index: number, nearestPoints: number[]) => {
        utils.getNearest(coordinate).then((coord_street: any) => {
            nearestPoints.push(coord_street);
        }).finally(() => {
            if (props.passengerCoordinates && props.passengerCoordinates.length - 1 > index) {
                const element = props.passengerCoordinates[index + 1];
                // eslint-disable-next-line prefer-const
                let coordinate = [element.Longitude, element.Latitude];
                getNearestNext(coordinate, index + 1, nearestPoints);
            } else {
                setPoints(nearestPoints);
            }
        });
    }

    const polygonVectorSource = shuttlRouteByDate && shuttlRouteByDate.length > 0 && geojsonObject && vector({
        features: new GeoJSON().readFeatures(geojsonObject, {
            featureProjection: get("EPSG:3857"),
        }),
    });

    const allShuttlesVectorSource = allShuttlesPolygonFeature && vector({
        features: new GeoJSON().readFeatures(allShuttlesPolygonFeature, {
            featureProjection: get("EPSG:3857"),
        }),
    });

    const vectorSource = vector({ features });

    const getDeviceHistoryRouteSource = () => {

        let temp = {
            "type": "FeatureCollection",
            "features": [

            ]
        };

        let shuttleCoors: number[][] = [];
        if (props.deviceHistoryRouteCoordinates) {


            props.deviceHistoryRouteCoordinates?.forEach(element => {
                shuttleCoors.push([element.lng, element.lat] as never);
            });

            var tempFeature = {
                "type": "Feature",
                "properties": {
                    "name": `${props.deviceHistoryRouteCoordinates[0].code}`
                },
                "geometry": {
                    "type": "MultiPolygon",
                    "coordinates": []
                }
            }

            tempFeature.geometry.coordinates.push([shuttleCoors] as never);

            temp.features.push(tempFeature as never);
        }

        return vector({
            features: new GeoJSON().readFeatures(temp, {
                featureProjection: get("EPSG:3857"),
            }),
        });

    }

    return (
        <FHMap center={fromLonLat(center)} zoom={zoom} deviceHistory={props.deviceHistoryRouteCoordinates}>
            <Layers>
                <TileLayer source={new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
                })} zIndex={0} />

                {props.deviceHistoryRouteCoordinates && props.deviceHistoryRouteCoordinates.length > 0 && (
                    <VectorLayer
                        source={getDeviceHistoryRouteSource()}
                        style={FeatureStyles.Polygon}
                    />
                )}

                {shuttlRouteByDate && shuttlRouteByDate.length > 0 && geojsonObject && (
                    <VectorLayer
                        source={polygonVectorSource}
                        style={FeatureStyles.Polygon}
                    />
                )}

                {routeFeatures && <VectorLayer features={routeFeatures} style={FeatureStyles.Polygon} />}
                {allShuttlesVectorSource && <VectorLayer source={allShuttlesVectorSource} style={FeatureStyles.MultiPolygonColorful} />}
                {features && <VectorLayer source={vectorSource} />}
                {schoolFeature && <VectorLayer features={[schoolFeature]} />}
                {busFeature && <VectorLayer features={[busFeature]} />}

            </Layers>
            <Controls>
                {props.isInteractionsOpen && <FHMapInteractions source={vectorSource} />}
                <FullScreenControl />
                <FHOverlays enable={!props.isInteractionsOpen}></FHOverlays>
            </Controls>
        </FHMap>
    );
};

export default TrackingMapApp;