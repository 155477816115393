import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import Text from 'ol/style/Text';

function randomColor() {
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
    var color = 'rgb(' + r + ',' + g + ',' + b + ')';

    return (color);
}

export default {
    Point: new Style({
        image: new CircleStyle({
            radius: 10,
            fill: undefined,
            stroke: new Stroke({
                color: "magenta",
            }),
        }),
    }),
    Polygon: new Style({
        stroke: new Stroke({
            color: "rgba(30, 144, 255, 0.9)",
            lineDash: [8, 4],
            width: 2,
        }),
        // fill: new Fill({
        //     color: "rgba(135, 206, 250, 0.4)",
        // }),
        text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
                color: 'black',
            }),
            stroke: new Stroke({
                color: 'white',
                width: 2,
            }),
        }),
    }),
    MultiPolygon: new Style({
        stroke: new Stroke({
            color: "blue",
            width: 1,
        }),
        fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
        }),
    }),
    MultiPolygonColorful: function (feature: any) {
        return new Style({
            fill: new Fill({
                color: randomColor(),
            }),
            stroke: new Stroke({
                color: 'rgba(255,255,255,0.8)',
            }),
            text: new Text({
                text: feature.getProperties().name,
                font: '18px',
                fill: new Fill({ color: 'green' }),
                stroke: new Stroke({ color: '#ffffff', width: 3 }),
                placement: "Point",
                overflow: true
            })
        });
    },
};