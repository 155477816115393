
import { Key, useContext, useState } from "react";
import './../i18n'
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  List,
  message,
  Modal,
  Row,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import React from "react";
import ActiveShuttleInfo from "../components/report/activeShuttle";
import TrackingMapApp from "../components/Map/TrackingMapApp";
import CarContext from "../Context/CarContext";
import { Car } from "../model/Car";
import { CarOutlined, CloseOutlined, HistoryOutlined, InfoCircleOutlined, PauseOutlined, SettingOutlined, StopOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { DeviceHistoryTrack } from "../model/DeviceHistoryTrack";
import { getDeviceHistoryTrack } from "../api/device";
import { View } from "ol";

function TrackingDashboard() {
  const contentList = {
    tab1: <ActiveShuttleInfo></ActiveShuttleInfo>,
    tab2: <p>Arac Listesi</p>,
  };
  const [carItems, setCarItems] = useContext(CarContext);
  const [activeTab, setActiveTab] = useState('tab1' as string);
  const [selectedCar, setSelectedCar] = useState(null as unknown as Car);
  const [activeCarItems, setActiveCarItems] = useState([] as Car[]);
  const [followSelectedCar, setFollowSelectedCar] = useState(false);
  const [deviceHistoryRouteCoordinates, setDeviceHistoryRouteCoordinates] = useState([] as DeviceHistoryTrack[]);
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const getYesterday = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today;
  }
  const [startDate, setStartDate] = useState(getYesterday());
  const [endDate, setEndDate] = useState(new Date());
  const [watchHistoryTrack, setWatchHistoryTrack] = useState(false);

  const carImeiActivated = true;
  const texts = {
    follow: 'Takip Et',
    unfollow: 'Takipten Çık',
    trackHistory: 'Geçmişi İzle',
    stopTracking: 'Takibi Durdur',
    settings: 'Ayarlar',
    offlineMessage: 'Araç çevrimdışı',
  };

  const handleTabChange = (key: React.SetStateAction<string>) => {
    setActiveTab(key);
  };

  const tabList = [
    {
      key: 'tab1',
      tab: 'Hareket Halindeki'
    },
    {
      key: 'tab2',
      tab: 'Tümü'
    }
  ];

  const handleOnPressFollowSelectedCar = () => {
    setFollowSelectedCar(!followSelectedCar);
  }

  const handleOnPressRouteHistory = () => {

    setWatchHistoryTrack(!watchHistoryTrack);

    if (deviceHistoryRouteCoordinates.length > 0) {
      setDeviceHistoryRouteCoordinates([] as DeviceHistoryTrack[]);
      return;
    }

    const startOfDayUnixTime = Math.floor(startDate.getTime() / 1000);
    const endOfDayUnixTime = Math.floor(endDate.getTime() / 1000);

    // setLoading(true);

    getDeviceHistoryTrack(selectedCar.IMEI?.toString(),
      startOfDayUnixTime.toString(),
      endOfDayUnixTime.toString(),
      'wgs84', '0')
      .then((result: any) => {
        if (result && result['data'] && result['data'].length > 0) {
          setDeviceHistoryRouteCoordinates(result['data'] as DeviceHistoryTrack[]);
          console.log(result['data'])
        } else {
          message.success({
            content: 'Gecmis rota bulunamadi!',
            style: {
              zIndex: "999999999999999",
              marginTop: '200vh',
            },
          });
        }
      })
    // .finally(() =>
    //   setLoading(false)
    // )
  }

  const CarDetails = ({ car }: any) => {
    return <Row>
      <Card hidden={car == null} className="card-billing-info" bordered={false}>
        <div className="col-info">
          <Descriptions layout="horizontal" style={{ minWidth: 60, minHeight: 30 }}>
            <Descriptions.Item label="Araç Plakası" span={3}>{car.CarNumberPlate}</Descriptions.Item>
            <Descriptions.Item label="IMEI" span={3}>{car.IMEI}</Descriptions.Item>
            <Descriptions.Item label="Yolcu Sayısı" span={3}>{car.PassengerCount}</Descriptions.Item>
            <Descriptions.Item label="Muayene Tarihi" span={3}>{car.VehicleInspectionDate}</Descriptions.Item>
            <Descriptions.Item label="Sigorta Tarihi" span={3}>{car.InsuranceDate}</Descriptions.Item>
            <Descriptions.Item label="KM" span={3}>{car.KM}</Descriptions.Item>
          </Descriptions>
        </div>
      </Card>
      <Row style={{ textAlign: "center", justifyContent: "center" }}>
        <Space>
          <Tooltip title={followSelectedCar ? texts.unfollow : texts.follow}>
            <Button
              onClick={() => (carImeiActivated ? handleOnPressFollowSelectedCar() : alert(texts.offlineMessage))}
              icon={<CarOutlined style={{ fontSize: 16, color: '#2196F3' }} />}
              size="small"
              style={{ minWidth: 60, minHeight: 30 }}
            >
            </Button>
          </Tooltip>
          <Tooltip title={watchHistoryTrack ? texts.stopTracking : texts.trackHistory}>
            <Button
              icon={<HistoryOutlined style={{ fontSize: 16, color: '#FF9800' }} />}
              size="small"
              style={{ minWidth: 60, minHeight: 30 }}
              onClick={handleOnPressRouteHistory}
            >
            </Button>
          </Tooltip>
          <Tooltip title={"Animasyon"}>
            <Button
              icon={<HistoryOutlined style={{ fontSize: 16, color: '#FF9800' }} />}
              size="small"
              style={{ minWidth: 60, minHeight: 30 }}
              id="start-animation"
            >
            </Button>
          </Tooltip>
          <Tooltip title={texts.settings}>
            <Button
              icon={<SettingOutlined style={{ fontSize: 16, color: '#4CAF50' }} />}
              size="small"
              style={{ minWidth: 60, minHeight: 30 }}
            >
            </Button>
          </Tooltip>
        </Space>
      </Row>
    </Row>
  }

  interface CarDataType extends Car {
    key: React.Key;
  }

  const columns: TableColumnsType<CarDataType> = [
    { title: 'Plaka No', dataIndex: 'CarNumberPlate', key: 'CarNumberPlate' },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: () => <a>Delete</a>,
    },
  ];

  const TabListComponent = ({ items }: any) => {

    const carData: CarDataType[] = items.map((item: any) => ({
      ...item,
      key: item.CarNumberPlate
    }));

    if (items.length === 0) {
      return <Card style={{ textAlign: 'center', marginTop: '20px' }}>
        <Row justify="center">
          <Col>
            <StopOutlined style={{ fontSize: '60px', color: '#FF6347', marginBottom: '20px', transform: 'rotate(90deg)' }} />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Col>
            <Title level={4} style={{ color: '#8c8c8c' }}>
              Bütün araçlar park halinde.
            </Title>
          </Col>
        </Row>
      </Card>;
    }

    return <Row justify="center">
      <Col span={20}> {/* Sağdan ve soldan 20% boşluk */}
        <Table<CarDataType>
          columns={columns}
          expandable={{
            expandedRowRender: (record) => <CarDetails car={record} ></CarDetails>,
            onExpand: (expanded, record) => {
              setSelectedCar(expanded ? (record as Car) : null as unknown as Car);
            },
            expandedRowKeys: selectedCar ? [selectedCar.CarNumberPlate] : []
          }
          }
          dataSource={carData}
        />
      </Col>
    </Row>
  }

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
          <Card bordered={false} className="criclebox cardbody h-full">
            <div className="project-ant">
              <div>
                <Paragraph className="lastweek">
                </Paragraph>
              </div>
            </div>

            <TrackingMapApp
              watchShuttleBus={false}
              setActiveCarItems={setActiveCarItems}
              followSelectedCar={followSelectedCar}
              selectedCar={selectedCar}
              deviceHistoryRouteCoordinates={deviceHistoryRouteCoordinates}></TrackingMapApp>

          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24" >

          <Card
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={handleTabChange}
            title={'Araçlar'}
          >
            {{
              tab1: (<TabListComponent items={activeCarItems} />),
              tab2: (<TabListComponent items={carItems} />),
            }[activeTab]}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default TrackingDashboard;
